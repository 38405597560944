import React, { useState, useContext } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { GlobalStateContext } from '../../context/GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo/unnamed.png';

function SignIn() {
    const [user, setUser] = useState(null); // Initially, user is null
    const { setProfile } = useContext(GlobalStateContext); 
    const navigate = useNavigate();

    // Configure Google login with the required scopes
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
      

    // Use the redirectUri where necessary, for example, in the Google OAuth setup
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                const userInfoResponse = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, 
                    {
                        headers: {
                            Authorization: `Bearer ${tokenResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    }
                );
    
                setProfile(userInfoResponse.data);
                sessionStorage.setItem('sessionId', tokenResponse.access_token);
                sessionStorage.setItem('userImage', userInfoResponse.data.picture);                
                navigate('/admin');
            } catch (error) {
                console.log('Error fetching user info:', error);
            }
        },
        onError: (error) => console.log('Login Failed:', error),
        scope: "https://www.googleapis.com/auth/spreadsheets",
        redirectUri: 'https://alessiobutturini.it/oauth/callback' // or your exact path

    });
        
    // Log out function to log the user out of Google and reset the profile state
    const logOut = () => {
        googleLogout();
        setProfile(null);
        sessionStorage.removeItem('sessionId'); // Clear the access token
    };

    return (
        <div className="mt-[30vh] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

                <div className="flex items-center justify-center">
                    <div className="flex justify-center items-center  me-0 sme-2 py-">
                        <img src={Logo} alt='' className='bg-contain'/>
                    </div>
                </div>

                <button 
                    onClick={login} 
                    className="linear mt-2 w-full rounded-xl bg-[#9fea47] py-[12px] text-base font-medium transition duration-200 opacity-90 hover:opacity-95 active:opacity-100 text-black"
                >
                    Sign in with Google 🚀
                </button>
            </div>
        </div>
    );
}

export default SignIn;
