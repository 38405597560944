/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Links from "./components/Links";
import Logo from "../../assets/logo/unnamed.png";
import routes from "routes.js";
import { MdOutlineLogin, MdOutlineLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import LogoAnimation from '../../assets/lottie/logo.json'; // Replace with the actual path to your Lottie JSON file

const Sidebar = ({ open, onClose }) => {
  const sidebarRef = useRef(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [authInstance, setAuthInstance] = useState(null);
  const clientId = '112490898183-4l6cj12663ogkgaqg85g8vqq4gd33v4g.apps.googleusercontent.com'; // Replace with your actual OAuth 2.0 client ID
  const navigate = useNavigate(); // Create navigate function

  useEffect(() => {
    function start() {
      gapi.load('client:auth2', () => {
        gapi.client.init({
          clientId: clientId,
          discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
          scope: "https://www.googleapis.com/auth/spreadsheets",
        }).then(() => {
          const auth = gapi.auth2.getAuthInstance();
          setAuthInstance(auth);
          setIsSignedIn(auth.isSignedIn.get());
          auth.isSignedIn.listen(setIsSignedIn);
        }).catch((error) => {
          console.error('Error initializing GAPI client:', error);
        });
      });
    }

    start();
  }, []);

  const handleSignIn = async () => {
    if (authInstance) {
      await authInstance.signIn();
    }
  };

  const handleSignOut = async () => {
    if (authInstance) {
      await authInstance.signOut();
    }
    navigate('/auth/sign-in');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (window.innerWidth < 1200) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={sidebarRef}
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-grey-700 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >

    <div className="flex items-center justify-center">
      <div className="flex justify-center items-center w-56 me-0 sme-2 py-">
        <img src={Logo} alt='' className='bg-contain'/>
      </div>
    </div>

      {/* Nav item */}

      <ul className="mb-auto">
        <Links routes={routes} />

        {isSignedIn ? (
            <button
              onClick={handleSignOut}
              className="text-red-500 font-semibold flex items-center py-2 px-8 hover:text-red-700"
              title="Sign Out"
            > 
              <MdOutlineLogout className="h-6 w-6 me-4" />
              Sign Out
            </button>
          ) : (
            <button
              onClick={handleSignIn}
              className="text-blue-500 font-semibold flex items-center py-2 px-8 hover:text-blue-700"
              title="Sign In"
            >
              <MdOutlineLogin className="h-6 w-6 me-4" />
              Sign In
            </button>
          )}

      </ul>
      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
