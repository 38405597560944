import React, { useState, useEffect } from 'react';
import { gapi } from 'gapi-script';
import { IoClose } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import { FaRegCalendarAlt } from "react-icons/fa";

const AddRowModal = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    Mese: '',
    Cliente: '',
    Brand: '',
    Campagna: '',
    Formato: '',
    Live: '',
    End: '',
    Direz: '',
    Buying: '',
    InvioPiano: '',
    ConfermaPiano: '',
    InvioPreno: '',
    SchedaMat: '',
    Trafficking: '',
    ArrivoMateriali: '',
    FLInserito: '',
    DEFBarber: '',
    ConsuntivatoNETBOX: '',
    DataGathering: '',
    Report: '',
    Accuen: '',
    D2S: '',
    Reservation: '',
    SMS: '',
    DEM: '',
    Social: '',
    Budget: ''
  });
  const [isSaving, setIsSaving] = useState(false);
  const spreadsheetId = '1SJbxbxlYhk4NH78W6KIn1EOsTPAC34XIy2GnObPd_-g'; // Replace with your spreadsheet ID

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  useEffect(() => {
    // Calculate budget whenever numeric fields change
    const numericFields = [
      'Accuen',
      'D2S',
      'Reservation',
      'SMS',
      'DEM',
      'Social'
    ];
    const total = numericFields.reduce((sum, field) => {
      const value = parseFloat(formData[field].replace(/[^0-9.-]+/g, ''));
      return sum + (isNaN(value) ? 0 : value);
    }, 0);
    setFormData(prevData => ({
      ...prevData,
      Budget: total.toFixed(2) // Format as two decimal places
    }));
  }, [
    formData.Accuen,
    formData.D2S,
    formData.Reservation,
    formData.SMS,
    formData.DEM,
    formData.Social
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: date ? date.toISOString().split('T')[0] : '' // Use ISO format for date
    }));
  };

  const handleSave = async () => {
    const accessToken = sessionStorage.getItem('sessionId');
    if (!accessToken) {
      console.error('No access token found!');
      return;
    }

    setIsSaving(true);

    try {
      await gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: spreadsheetId,
        range: 'Foglio1',
        valueInputOption: 'USER_ENTERED',
        resource: {
          values: [Object.values(formData)],
        },
      });

      console.log('Google Sheet updated successfully.');
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error adding new row:', error.result.error.message);
    } finally {
      setIsSaving(false);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
      <div className="bg-white text-black rounded-lg shadow-lg w-full max-w-md md:max-w-lg h-auto md:h-3/4 relative overflow-hidden">
        <div className="flex justify-between items-center p-4 bg-black-500 rounded-t-lg">
          <h2 className="text-xl font-semibold text-white">Add New Campaign</h2>
          <button onClick={onClose} className="text-red-500">
            <IoClose className="text-xl" />
          </button>
        </div>
        <div className="overflow-auto scrollbar-hidden py-4 px-10 mt-2 h-[calc(100%-80px)]">
          <div className="space-y-4">
            {Object.keys(formData).map((key, index) => {
              if (['Direz', 'Buying', 'InvioPiano', 'ConfermaPiano', 'InvioPreno', 'SchedaMat', 'Trafficking', 'ArrivoMateriali', 'FLInserito', 'DEFBarber', 'ConsuntivatoNETBOX', 'DataGathering', 'Report'].includes(key)) {
                return (
                  <div key={index} className="mb-4 text-black-500">
                    <label className="block text-sm font-bold mb-1">{key}</label>
                    <select
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg"
                    >
                      <option value="">Select</option>
                      <option value="si">Si</option>
                      <option value="no">No</option>
                      <option value="in attesa">In attesa</option>
                    </select>
                  </div>
                );
              }

              if (['Live', 'End', 'InvioPiano', 'ConfermaPiano', 'InvioPreno', 'SchedaMat', 'Trafficking', 'ArrivoMateriali', 'FLInserito', 'DEFBarber', 'ConsuntivatoNETBOX', 'DataGathering', 'Report'].includes(key)) {
                return (
                  <div key={index} className="mb-4 text-black-500">
                    <label className="block text-sm font-bold mb-1">{key}</label>
                    <div className='flex justify-start items-center'>
                      <FaRegCalendarAlt className='me-4'/>
                      <DatePicker
                        selected={formData[key] ? new Date(formData[key]) : null}
                        onChange={(date) => handleDateChange(key, date)}
                        dateFormat="dd/MM/yyyy"
                        className="w-full px-3 py-2 border rounded-lg"
                      />
                    </div>
                  </div>
                );
              }

              if (['Accuen', 'D2S', 'Reservation', 'SMS', 'DEM', 'Social'].includes(key)) {
                return (
                  <div key={index} className="mb-4 text-black-500">
                    <label className="block text-sm font-bold mb-1">{key}</label>
                    <input
                      type="text"
                      name={key}
                      value={`€ ${formData[key]}`}
                      onChange={(e) => handleChange({
                        target: {
                          name: key,
                          value: e.target.value.replace(/[^0-9]/g, '') // Allow only numbers
                        }
                      })}
                      className="w-full px-3 py-2 border rounded-lg"
                      placeholder={`Enter ${key} in euros`}
                    />
                  </div>
                );
              }

              if (key === 'Budget') {
                return (
                  <div key={index} className="mb-4 text-black-500">
                    <label className="block text-sm font-bold mb-1">{key}</label>
                    <input
                      type="text"
                      name={key}
                      value={`€ ${formData[key]}`}
                      readOnly
                      className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                    />
                  </div>
                );
              }

              return (
                <div key={index} className="mb-4 text-black-500">
                  <label className="block text-sm font-bold mb-1">{key}</label>
                  <input
                    type="text"
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder={`Enter ${key}`}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-center space-x-2 mt-6">
            <button
              onClick={onClose}
              className="w-36 py-2 bg-red-500 text-white rounded-lg"
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="w-36 py-2 bg-blue-500 text-white rounded-lg"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRowModal;
