import React from "react";

// Admin Imports
import Home from "views/admin/home";
// Auth Imports
import SignIn from "views/auth/SignIn";
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaRegCalendarTimes } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import { BsCashCoin } from "react-icons/bs";



// Icon Imports
import {
  MdOutlineLogout, 
} from "react-icons/md";
import ActiveTable from "views/admin/activeDataTable";
import ClosedTable from "views/admin/closedDataTable";
import OnGoingTable from "views/admin/onGoingTable";
import CashTable from "views/admin/cashDataTable";


const routes = [
  {
    name: "All Campaigns",
    layout: "/admin",
    path: "dashboard",
    icon: <FaRegCalendarAlt  className="h-6 w-6" />,
    component: <Home />,
  },
  {
    name: "Active",
    layout: "/admin",
    path: "active",
    icon: <FaRegCalendarCheck className="h-6 w-6" />,
    component: <ActiveTable />,
  },
  {
    name: "Closed",
    layout: "/admin",
    path: "closed",
    icon: <FaRegCalendarTimes  className="h-6 w-6" />,
    component: <ClosedTable />,
  },
  {
    name: "On Going",
    layout: "/admin",
    path: "on-going",
    icon: <IoRocketOutline  className="h-6 w-6" />,
    component: <OnGoingTable />,
  },
  {
    name: "Budget",
    layout: "/admin",
    path: "budget",
    icon: <BsCashCoin  className="h-6 w-6" />,
    component: <CashTable />,
  },
  {
    name: "Esci",
    layout: "/auth",
    path: "sign-in",
    icon: <MdOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  }
];
export default routes;
