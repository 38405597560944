import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import { FaSyncAlt, FaPen, FaEye } from 'react-icons/fa'; // Import FaPlus for add row
import EditModal from '../views/admin/home/components/EditModal';
import DetailModal from '../views/admin/home/components/DetailPopup'; // Import the DetailModal component

const AllDataTable = () => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  
  const headers = [
    "Mese", "Cliente", "Brand", "Campagna", "Formato", "Live", "End", 
    "Direz. Commerc.", "Buying", "Invio Piano", "Conferma Piano", 
    "Invio Preno", "Scheda Mat.", "Trafficking", "Arrivo Materiali", 
    "FL Inserito", "DEF Barber", "Consuntivato NETBOX", "Data Gathering", 
    "Report", "Accuen", "D2S", "Reservation", "SMS", "DEM", "Social", "Budget"
  ];

  const spreadsheetId = '1SJbxbxlYhk4NH78W6KIn1EOsTPAC34XIy2GnObPd_-g'; // Replace with your spreadsheet ID

  const fetchTableData = async () => {
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: spreadsheetId,
        range: 'Foglio1',
      });
      if (response.status === 200) {
        const data = response.result.values;
        setTableData(data);
        setFilteredData(data);
      } else {
        console.log('Error fetching data:', response.status);
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };

  const handleRefreshClick = () => {
    setIsRefreshing(true);
    fetchTableData().finally(() => {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2000);
    });
  };

  const openEditModal = (rowData, rowIndex) => {
    setCurrentRowData(rowData);
    setCurrentRowIndex(rowIndex);
    setIsEditModalOpen(true);
  };

  const openDetailModal = (rowData) => {
    setCurrentRowData(rowData);
    setIsDetailModalOpen(true);
  };

  const handleSave = (updatedData) => {
    const updatedTableData = [...tableData];
    if (currentRowIndex !== null) {
      updatedTableData[currentRowIndex + 1] = updatedData;
      setTableData(updatedTableData);
      filterData(searchQuery);
    }
  };

  useEffect(() => {
      handleRefreshClick();
  }, []);

  const filterData = (query) => {
    const today = new Date();
    const filtered = tableData.filter(row => {
      const liveDate = new Date(row[5]?.split('/').reverse().join('-'));
      const endDate = new Date(row[6]?.split('/').reverse().join('-'));
      
      return (
        (!query || row[1]?.toLowerCase().includes(query.toLowerCase())) 
        // &&
        // (!isNaN(liveDate) && !isNaN(endDate) && today >= liveDate && today <= endDate)
      );
    });
    setFilteredData(filtered);
    console.log({filtered});
  };

  useEffect(() => {
    filterData(searchQuery);    
  }, [searchQuery, tableData]);

  return (
    <div className="bg-white text-black-500 lg:px-10 rounded-xl relative">
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-3xl text-center font-semibold text-[#7030a0]">Budget Campaigns</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleRefreshClick}
            className="text-[#ff5d5d] font-semibold flex items-center space-x-2 hover:text-blue-700"
            title="Refresh Data"
          >
            <FaSyncAlt
              className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`}
            />
            Refresh
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Cliente..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full text-black">
          <thead>
            <tr className="bg-[#9fea47] text-black-500">
              <th className="py-2 px-4 border border-black-500 text-center">Edit</th>
              <th className="py-2 px-4 border border-black-500 text-center">View</th>
              {headers.slice(0, 2).concat(headers.slice(-7)).map((header, index) => (
                <th key={index} className="py-2 px-2 border border-black-500 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.slice(0).map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openEditModal(row, rowIndex)}>
                    <FaPen className='text-blue-700' />
                  </button>
                </td>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openDetailModal(row)}>
                    <FaEye className='text-green-700' />
                  </button>
                </td>
                {row.slice(0, 2).concat(row.slice(-7)).map((cell, cellIndex) => (
                  <td key={cellIndex} className="py-2 px-2 border border-black-500 text-center">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={headers.slice(0, 2).concat(headers.slice(-7)).length + 2} className="py-2 px-4 border border-black-500 text-center text-black-500">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <EditModal
        show={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        rowData={currentRowData}
        onSave={handleSave}
        rowIndex={currentRowIndex}
        headers={headers}
      />

      <DetailModal
        show={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
        rowData={currentRowData}
        headers={headers}
      />

    </div>
  );
};

export default AllDataTable;
