// src/context/GlobalStateContext.js
import React, { createContext, useState, useEffect } from 'react';
import { gapi } from 'gapi-script';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const spreadsheetId = '1SJbxbxlYhk4NH78W6KIn1EOsTPAC34XIy2GnObPd_-g'; // Replace with your spreadsheet ID

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: spreadsheetId,
        range: 'Foglio1',
      });
      if (response.status === 200) {
        const data = response.result.values;
        setTableData(data);
        setFilteredData(data);
      } else {
        console.log('Error fetching data:', response.status);
      }
    } catch (error) {
      console.log('Error during API request:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(); // Initial data fetch
  }, []);

  return (
    <GlobalStateContext.Provider
      value={{
        profile,
        setProfile,
        tableData,
        filteredData,
        setTableData,
        setFilteredData,
        isLoading,
        fetchTableData,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
