import React, { useState, useEffect, useCallback } from 'react';
import { gapi } from 'gapi-script';
import DatePicker from 'react-datepicker';
import { IoClose } from 'react-icons/io5';
import { FaRegCalendarAlt } from "react-icons/fa";
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import { FaEuroSign } from "react-icons/fa6";

const EditModal = ({ show, onClose, rowData, onSave, rowIndex, headers }) => {
  const [editedData, setEditedData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [numericFieldsChanged, setNumericFieldsChanged] = useState(false); // To track numeric field changes

  const spreadsheetId = '1SJbxbxlYhk4NH78W6KIn1EOsTPAC34XIy2GnObPd_-g'; // Replace with your spreadsheet ID

  useEffect(() => {
    if (show) {
      if (rowData && Array.isArray(rowData)) {
        setEditedData(rowData); // Initialize with rowData
      } else {
        console.error('Invalid rowData:', rowData);
        setEditedData([]);
      }
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show, rowData]);

  useEffect(() => {
    if (numericFieldsChanged) {
      // Update budget only if numeric fields changed
      const numericValues = numericHeaders.map(header => {
        const index = headers.indexOf(header);
        return index !== -1 ? parseFloat(editedData[index]) || 0 : 0;
      });

      const total = numericValues.reduce((acc, value) => acc + value, 0);
      const budgetIndex = headers.indexOf(budgetHeader);

      if (budgetIndex !== -1) {
        setEditedData(prevData => {
          const newData = [...prevData];
          newData[budgetIndex] = total.toFixed(2); // Format as two decimal places
          return newData;
        });
      }
      setNumericFieldsChanged(false); // Reset the flag after updating the budget
    }
  }, [numericFieldsChanged, editedData, headers]);

  const handleChange = (index, value) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  const handleNumericChange = (index, value) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[index] = value === '' ? '' : parseFloat(value) || '';
      return newData;
    });
    setNumericFieldsChanged(true); // Indicate that numeric fields have changed
  };

  const handleDateChange = (index, date) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[index] = date ? date.toISOString().split('T')[0] : ''; // Use ISO format for dates
      return newData;
    });
  };

  const handleSelectChange = (index, value) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[index] = value;
      return newData;
    });
  };

  const handleSave = async () => {
    const accessToken = sessionStorage.getItem('sessionId');
    if (!accessToken) {
      console.error('No access token found!');
      return;
    }

    setIsSaving(true);

    try {
      const lastColumn = getColumnLetter(editedData.length - 1);

      await gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId,
        range: `Foglio1!A${rowIndex + 1}:${lastColumn}${rowIndex + 1}`, // Adjusted dynamic range
        valueInputOption: 'USER_ENTERED',
        resource: {
          values: [editedData],
        },
      });

      console.log('Google Sheet updated successfully.');
      onSave(editedData); // Update parent component data
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating row:', error.result.error.message);
    } finally {
      setIsSaving(false);
    }
  };

  // Function to get column letter from index
  const getColumnLetter = (index) => {
    let column = '';
    let dividend = index + 1;
    let modulo;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      column = String.fromCharCode(modulo + 65) + column;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return column;
  };

  // Define which fields should use a select input
  const selectHeaders = [
    'Direz. Commerc.', 'Buying', 'Invio Piano', 'Conferma Piano', 'Invio Preno', 
    'Scheda Mat.', 'Trafficking', 'Arrivo Materiali', 'FL Inserito', 
    'DEF Barber', 'Consuntivato NETBOX', 'Data Gathering', 'Report'
  ];

  // Numeric headers for total calculation
  const numericHeaders = [
    'Accuen', 'D2S', 'Reservation', 'SMS', 'DEM', 'Social'
  ];

  const budgetHeader = 'Budget';

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
      <div className="bg-white text-black rounded-lg shadow-lg w-full max-w-md md:max-w-lg h-auto md:h-3/4 relative overflow-hidden">
        <div className="flex justify-between items-center p-4 bg-black-500 rounded-t-lg">
          <h2 className="text-xl font-semibold text-white">Edit Campaign</h2>
          <button onClick={onClose} className="text-red-500">
            <IoClose className="text-xl" />
          </button>
        </div>
        <div className="overflow-auto scrollbar-hidden py-4 px-10 mt-2 h-[calc(100%-80px)]">
          <div className="space-y-4">
            {headers.map((header, index) => {
              const cell = editedData[index];
              const isDate = header === 'Live' || header === 'End';
              const isSelect = selectHeaders.includes(header);
              const isNumeric = numericHeaders.includes(header);

              return (
                <div key={index} className="mb-4 text-black-500">
                  <label className="block text-sm font-bold mb-1">{header}</label>
                  {isSelect ? (
                    <select
                      value={cell || ''}
                      onChange={(e) => handleSelectChange(index, e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg"
                    >
                      <option value="">Select</option>
                      <option value="si">Si</option>
                      <option value="no">No</option>
                      <option value="in attesa">In attesa</option>
                    </select>
                  ) : isDate ? (
                    <div className='flex justify-start items-center'>
                      <FaRegCalendarAlt className='me-4'/>
                      <DatePicker
                        selected={cell ? new Date(cell) : null}
                        onChange={(date) => handleDateChange(index, date)}
                        dateFormat="dd/MM/yyyy"
                        className="w-full px-3 py-2 border rounded-lg"
                      />
                    </div>
                  ) : isNumeric ? (
                    <div className='flex justify-start items-center border rounded-lg px-2'>
                    €
                    <input
                      type="text"
                      value={cell === '' ? '0' : cell}
                      onChange={(e) => handleNumericChange(index, e.target.value)}
                      className="w-full ms-2 px-2 py-2"
                      placeholder={``}
                    />
                    </div>
                  ) : header === budgetHeader ? (
                    <input
                      type="text"
                      value={`€ ${cell || ''}`}
                      readOnly
                      className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                      placeholder={`Total Budget`}
                    />
                  ) : (
                    <input
                      type="text"
                      value={cell || ''}
                      onChange={(e) => handleChange(index, e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg"
                      placeholder={`Enter ${header}`}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex justify-center space-x-2 mt-6">
            <button
              onClick={onClose}
              className="w-36 py-2 bg-red-500 text-white rounded-lg"
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="w-36 py-2 bg-blue-500 text-white rounded-lg"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
