import React, { useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';

// Numeric headers for total calculation
const numericHeaders = [
  'Accuen', 'D2S', 'Reservation', 'SMS', 'DEM', 'Social', 'Budget'
];

const OrderDetailsPopup = ({ show, onClose, rowData, headers }) => {
  const popupDetailRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupDetailRef.current && !popupDetailRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
      <div
        ref={popupDetailRef}
        className="bg-white text-black-500 rounded-lg shadow-lg w-full max-w-md h-auto md:max-w-lg md:h-3/4 relative overflow-hidden"
      >
        <div className="flex justify-between items-center space-x-2 p-5 bg-black-500 z-50">
          <h2 className="text-xl font-semibold text-white ms-6">Campaign Detail</h2>
          <button
            onClick={onClose}
            className="text-red-500"
          >
            <IoClose className='text-xl' />
          </button>
        </div>
        <div className="absolute inset-0 overflow-auto scrollbar-hidden mt-20 pb-10 px-10 z-10">
          <table className="w-full text-left border-collapse">
            <tbody>
              {rowData.map((cell, index) => {
                const header = headers[index];
                // Determine if the header is a numeric header
                const isNumeric = numericHeaders.includes(header);
                return (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="font-medium py-2 px-4">{header}</td>
                    <td className="py-2 px-4">
                      {isNumeric ? `${cell === '' ? '0' : cell} €` : cell}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPopup;
