import React from 'react';
import { gapi } from 'gapi-script';

const DeleteModal = ({ show, onClose, rowData, spreadsheetId, fetchTableData, rowIndex }) => {
  if (!show) return null;

  const azienda = rowData[1];
  const campagna = rowData[2];
  const startDate = rowData[5];
  const endDate = rowData[6];

  const handleDelete = async () => {
    if (rowIndex !== null) {
      const requests = [
        {
          deleteDimension: {
            range: {
              sheetId: 0, // Assuming the sheet you are working on is the first sheet; replace with actual sheet ID if different
              dimension: 'ROWS',
              startIndex: rowIndex + 1, // +1 to account for the header row
              endIndex: rowIndex + 2, // +2 because endIndex is exclusive
            },
          },
        },
      ];

      try {
        await gapi.client.sheets.spreadsheets.batchUpdate({
          spreadsheetId: spreadsheetId,
          resource: {
            requests: requests,
          },
        });
        fetchTableData(); // Refresh the table data after deletion
        onClose(); // Close the modal after deletion
      } catch (error) {
        console.log('Error deleting row:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 glassmorphism bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl mb-4">Sei sicuro di voler cancellare definitivamente <br /> la seguente campagna?</h2>
        <div className="my-4">
          <ul>
            <li><b>azienda:</b> {azienda}</li>
            <li><b>campagna:</b> {campagna}</li>
            <li><b>data:</b> {startDate} - {endDate}</li>
          </ul>
        </div>
        <div className="flex justify-center space-x-5">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Torna indietro
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
            onClick={handleDelete}
          >
            Cancella
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
