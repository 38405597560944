// OAuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Handle the OAuth response
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code'); // Use this if you're doing the auth code flow

    if (code) {
      // Exchange the code for tokens here (if needed)
      // Then navigate to the admin dashboard or handle any logic as required
      navigate('/admin');
    } else {
      // Handle errors or invalid responses
      console.error('OAuth failed or was denied.');
      navigate('/auth/sign-in');
    }
  }, [navigate]);

  return <div>Processing OAuth Callback...</div>;
};

export default OAuthCallback;
