import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { GlobalStateProvider } from './context/GlobalStateContext';
import { GoogleOAuthProvider } from "@react-oauth/google"


import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId='1009282809407-sh8h2kgmot2q295a503sl5530pldnaj9.apps.googleusercontent.com'>
    <BrowserRouter>
      <GlobalStateProvider>
          <App />
      </GlobalStateProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>

);

