import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { gapi } from 'gapi-script';

const TokenManager = ({ children }) => {
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('sessionId'));
  const refreshToken = sessionStorage.getItem('refreshToken');


  
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '112490898183-4l6cj12663ogkgaqg85g8vqq4gd33v4g.apps.googleusercontent.com',
        discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
        scope: "https://www.googleapis.com/auth/spreadsheets",
      }).then(() => {
        // Make sure the user is signed in
        if (!gapi.auth2.getAuthInstance().isSignedIn.get()) {
          gapi.auth2.getAuthInstance().signIn();
        }
      }).catch((error) => {
        console.error('Error during gapi client initialization:', error);
      });
    }
  
    gapi.load("client:auth2", start);
  }, []);
  


  const getNewAccessToken = async () => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        client_id: '112490898183-4l6cj12663ogkgaqg85g8vqq4gd33v4g.apps.googleusercontent.com', // Replace with your OAuth 2.0 Client ID
        client_secret: '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', // Replace with your OAuth 2.0 Client Secret
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      });

      const newAccessToken = response.data.access_token;
      setAccessToken(newAccessToken);
      sessionStorage.setItem('sessionId', newAccessToken); // Update the session storage
      console.log('Token refreshed:', newAccessToken);
    } catch (error) {
      console.error('Failed to refresh token:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      console.error('No access token available!');
      return;
    }

    let tokenPayload;
    try {
      // Decode the token to find out its expiration time
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      tokenPayload = JSON.parse(atob(base64));

      if (!tokenPayload.exp) {
        throw new Error('Token does not contain an expiration claim');
      }
    } catch (error) {
      console.error('Error decoding access token:', error.message);
      return;
    }

    const expirationTime = tokenPayload.exp * 1000;
    const currentTime = Date.now();
    const timeLeft = expirationTime - currentTime;

    const refreshTime = timeLeft - 5 * 60 * 1000;

    const timer = setTimeout(() => {
      getNewAccessToken();
    }, refreshTime);

    return () => clearTimeout(timer);
  }, [accessToken]);

  return <>{children}</>;
};

export default TokenManager;
